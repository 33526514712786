<template>
  <div class="container">
    <header-back name='搜一搜'></header-back>
    <div class="seach-con top44">
      <van-search
        placeholder="请输入搜索关键词"
        shape="round"
        v-model="seachValue"
        focus
        @search="onSearch"
      />
      <van-empty
        v-if="list.length === 0"
        description="暂无历史搜索"
      />
      <div
        v-else
        class="seach-main"
      >
        <div class="seach-cell">
          <div class="seach-title">历史搜索</div>
          <div class="seach-value">
            <van-icon
              name="close"
              @click="onClose"
            />
          </div>
        </div>
        <div class="seach-tag">
          <div
            v-for="(item,index) in list"
            :key='index'
            class="seach-tag-t"
            @click="tagClick(item)"
          >{{item}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import utils from "@/utils/index";
export default {
  components: { HeaderBack },
  data() {
    return {
      seachValue: "",
      list: [],
    };
  },
  created() {
    this.$store.commit("menu/bottomId", 0);
    if (utils.getSessionStorage("seach")) {
      let arr = utils.getSessionStorage("seach");
      this.list = JSON.parse(arr) || [];
    }
  },
  methods: {
    onSearch(val) {
      this.list.push(val);
      utils.setSessionStorage("seach", this.list);
      this.$store.commit("menu/bottomId", 1);
      this.$router.push({
        name: "mall",
        params: {
          name: val,
        },
      });
    },
    tagClick(item) {
      this.$store.commit("menu/bottomId", 1);
      this.$router.push({
        name: "mall",
        params: {
          name: item,
        },
      });
    },
    onClose() {
      this.list = [];
      utils.removeSessionStorage("seach");
    },
  },
};
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  background-color: #fff;
}

.seach-cell {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
.seach-tag {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px;
  overflow: hidden;
}
.seach-title,
.seach-value {
  flex: 1;
}
.seach-value {
  text-align: right;
}
.seach-tag-t {
  width: 80px;
  display: inline-block;
  text-align: center;
  height: 36px;
  line-height: 36px;
  background: #f6f6f6;
  border-radius: 14px;
  float: left;
  color: #d7d7d7;
  font-size: 12px;
  margin-right: 20px;
}
</style>
